.work {
    padding: 4rem;
    background: #fff;
    background: linear-gradient(#fff,#b0efe4);
  }
  
  h1 {
    text-align: center;
  }
  .all{
    background: linear-gradient(#eb5959,#fff,#a8d6e5);
  }
  
  .work__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 95%;
    margin: 0 auto;
  }
  
  .work__wrapper {
    position: relative;
    margin: 50px 0 45px;
  }
  
  .work__items {
    margin-bottom: 24px;
  }
  
  .work__item {
    display: flex;
    flex: 1;
    margin: 0 1rem;
    border-radius: 10px;
  }
  
  .work__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
  }
  
  .work__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
  }
  
  .fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
  }
  
  .work__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: #1f98f4;
    box-sizing: border-box;
  }
  .team{
    display: flex;
    flex-flow: column;
    width: 100%;
   border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
    color: #242424;
    text-align: center;
    size: 20px;
    font-size: 40px;
    margin-top:30px  ;
    margin-bottom: 10px ;


  }
  .view{
    display: flex;
    flex-flow: column;
  }
  .h-goal{
    margin-bottom: 40px;
  }
  .goals{
    margin-bottom: 20px;
  }
  .par{
    font-size: medium;
  }
  .my-goals{
    
    text-align: center;
    align-items: center;
    max-width: 1120px;
    width: 95%;
    margin: 0 auto;
    display:flex;
    flex-direction: row;
    
  }
  .view{
    display: flex;
    flex-flow: row;
    padding: 5px;
            max-height: 40%;
  }
  .large{
    display: flex;
    flex-flow: column;
    text-align: start;
    width:80%;
    margin-left: 30px;
    padding-left: 10px;
    padding-bottom: 10px;
    padding-right: 50px;
    padding-top: 10px;
    margin-bottom: 100px;
    border:#242424 solid;
  }
  .mid4{
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .img-card{
    padding: 0px;
            max-height: 50%;
  }
  .work__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
  }
  
  
  .work__item__img:hover {
    transform: scale(1.1);
  }
  
  .work__item__info {
    padding: 20px 30px 30px;
  }
  
  .work__item__text {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
  }
  @media only screen and (max-width: 1024px) {
    .work__item {
      margin-bottom: 3rem;

    }.work__wrapper{
      margin-top: 60px;
      margin-left: -50px;
      margin-right: -50px;
      
    }
    .card{
      margin-left: 10px;
    }
    .view{
      display: flex;
      flex-flow: column;
    }
    .my-goals{
    
      text-align: center;
      align-items: center;
      width: 95%;
      margin: 0 auto;
      display:flex;
      flex-direction: column;
      margin-bottom: 10px;
      
    }
    .goal-2{
      margin-top: 40px;
      margin-bottom: 40px;

    }
   
  
  }
  