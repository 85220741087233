.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.tea{
  display: flex;
  flex-flow: row;
}
.in{
  margin-bottom: 100px;
  margin-left: 10px;
}
.slick-slide>div{
  margin: 0 10px;
}
.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #FFF;
  color: #25d366;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #fff;
  z-index: 100;
}

.whatsapp-icon {
  margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
      margin-top: 10px;
      
  }

  .whatsapp_float {
      width: 65px;
      height: 65px;
      bottom: 20px;
      right: 5px;
      font-size: 40px;
      
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.big{ 
  background: white;
  height: 250px;
  color:#282c34;
  border-radius: 20px 20px 20px 20px;
  width: 200px;
}
.inner{
  height: 70px;
  border-radius: 20px 20px 0 0;
  background-color: indianred;
  display: flex;
  justify-content: center;
  align-items: center;
}
.head{
  font-weight: 150px;
  text-align: center;
  font-size: 30px;
}
.review{
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 4px;
  
}
.feet{
  font-size: 20px;

}
.img{
  height: 60px;
  width: 60px;
  border-radius: 30px 30px 30px 30px;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.services,
.Contact,
.aboutus {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  background-image: url('../public/images/img-2.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.Contact {
  background-image: url('../public/images/img-5.jpg');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.aboutus {
  background-image: url('../public/images/img-7.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}
@media screen and (max-width: 960px) {
 .name{
  text-align: start;
  font-size: 80px;
 }
}