.main1{
    background: linear-gradient(rgb(216, 206, 238), #ecf8ed);
    margin-bottom:0px;
    align-items: center;
    align-content: center;
    display: flex;
       flex-flow: column;
       width: 100%;
       -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
       filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
       font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
   }
   .sec-main{
       margin-top: 40px;
       align-content: center;
       align-self: center;
       align-items:center;
       margin-bottom: 20px;
       display: flex;
       flex-flow: column;
   }
   .medium-main{
        align-content: center;
        align-self: center;
        align-items:center;
        margin-bottom: 20px;
        display: flex;
       flex-flow: column;
   }
   .l-main{
       align-content: center;
       align-self: center;
       align-items:center;
       margin-bottom: 20px;
       display: flex;
       flex-flow: column;
       color: #242424;
   }
   .text{
    color: #242424;
    display: flex;
    flex-flow:column;
    align-content: center;
       align-self: center;
       align-items:center;
       margin-bottom: 20px;
       width: 70%;
       text-align: center;
   }
   

  .mid{
    margin-left: 10px;
    margin-right: 10px;
  }
