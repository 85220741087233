 .foot-container{
 background-color: #242424;
 padding: 4rem 0 2rem 0;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 margin-bottom: 0px;
 }
 .mid{
  text-decoration: underline;
  display: flex;
  flex-flow: row;
 }
 .info{
  display: flex;
  flex-flow: row;

 }
 .call{
  display: flex;
  flex-flow:row;
 }
 .icon{
  margin-left: 10px;
  text-decoration: underline;
  display: flex;
  flex-flow: row;

 }
 .social-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center ;
    margin-bottom: -40px; 
  }
  .footer-logo{
    margin-bottom: 20px;
  }
  .Contact-info{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   text-align: center;
   margin-bottom:24px;
   padding: 24px;
   color:#fff;

  }
  .Contact-info > p{
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  }
  .footer-contact-heading{
    margin-bottom:24px;
    font-size: 24px;

  }
  .footer-contact-text{
    margin-bottom:24px;
    font-size: 20px;
  }

  .footer-input{
    padding:8px 20px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border:none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid #fff;


  }
  .footer-message{
    padding:8px 20px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border:none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid #fff;


  }
  .info{
    
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    flex-flow:row;
    color:#fff;
    margin-bottom: 20px;
  }
  a:link {
    color: #fff;
    background-color: transparent;
    text-decoration: none;
  }
  .btn1{
    background-color: beige;
    color: black;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
    width: 100%;
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-style: normal;
    font-size: 24px;
    font-weight: 800;
}
  .footer-links{
    width: 100%;
    max-width: 1000px;
    display:flex;
    justify-content:center;

  }
  .footer-links-wrapper{
    display:flex;}

  .footer-links-items{
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 160px;
    box-sizing: border-box;

  }
  .footer-links-items h2{
    margin-bottom: 16px;
  }

  .footer-links-items > h2{
    color:#fff;

  }
  .footer-links-items a{
    color: #fff;
    text-decoration: none;
    margin-bottom: 8px;
  }

  .footer-links-items a:hover{
    color:#fff;
    transition:0.3s ease-out;


  }

.footer-input::placeholder{
    color:#b1b1b1;

}
.footer-message::placeholder{
    color:#b1b1b1;

}

.footer-links{
    margin-bottom: 32px;
}
.website-rights{
    color:#fff;
    margin-bottom:-20px;
    margin-top: 20px;
    align-items: flex-start; /* Align to the left corner */  
}
.footer-logo{
    align-items: left;
}

@media screen and (max-width: 820px){
    .footer-links{
      padding-top:rem;

    }
    .footer-input{
        width:100%;

    }
    .footer-message{
        width:100%
    }
    .btn1{
        width: 100%;
    }
    .btn1{
        background-color: beige;
        color: black;
        padding: 8px 20px;
        border: 1px solid var(--primary);
        transition: all 0.3s ease-out;
        width: 100%;
        padding: 8px 20px;
        border-radius: 2px;
        outline: none;
        border: none;
        cursor: pointer;
        font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
        font-style: normal;
        font-size: 24px;
        font-weight: 800;
    }
    .footer-links-wrapper{

        flex-direction:column;

    }
    .social-media{
        flex-direction:none;
    }

}
  