.cards {
    padding: 4rem;
    background: linear-gradient(#ecf8ed,rgb(238, 206, 238))
  }
  
  h1 {
    text-align: center;
  }
  
  .cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 95%;
    margin: 0 auto;
  }
  .img-card{
    border-radius: 80px 80px 80px 80px;
    width: 150px;
    height: 150px;
    margin:center;
    text-align: center;
    

  }
  .card{ 
    text-align: center;
          max-width: 900px;
          margin: 10 auto;
          border: 1px solid #e6e6e6;
          padding: 70px 20px;
          margin-top: 50px;
          margin-bottom: 50px;
          width:95%;
          flex-flow:row;
          display: flex;
          justify-content: space-between;
          margin-left: 25px;
          margin-right: 25px;
          margin-top: 50px;
          margin-bottom: 50px;
          border-radius: 20px;
  }
  .card-mid{
   justify-content: center;
   align-items: center;
   align-self: flex-start;

  }
  .des-card{
    font-size: 20px;
   
  }
  .title-card{
   font-size: 40px;
  }
  
  .cards__wrapper {
    position: relative;
    margin: 50px 0 45px;
  }
  
  .cards__items {
    margin-bottom: 24px;
  }
  
  .cards__item {
    display: flex;
    flex: 1;
    margin: 0 1rem;
    border-radius: 10px;
  }
  
  .cards__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
  }
  
  .cards__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
  }
  
  .fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
  }
  
  .cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: #1f98f4;
    box-sizing: border-box;
  }
  .h-goals{
   margin-top: 60px;
   margin-bottom: -50px;
  }
  
  .cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
  }
  
  .cards__item__img:hover {
    transform: scale(1.1);
  }
  
  .cards__item__info {
    padding: 20px 30px 30px;
  }
  
  .cards__item__text {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
  }
  
  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .cards__items {
      display: flex;
      
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .cards__item {
      margin-bottom: 2rem;

    }.cards__wrapper{
      margin-left: -50px;
      margin-right: -50px;
      
    }
  }