.imp{
    background: linear-gradient( rgb(15, 15, 16),rgb(8, 7, 7));
    align-items: center;
    justify-content: center;
    text-align:center;
    color:aliceblue;
}
.heading{
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  padding-top: 100px;
  padding-bottom: 40px;
  font-size: 30px;
  text-decoration: underline;

}
.uslink{
    color: aliceblue;
    
}
.us-icon{
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: 50px;
}
.us-imp-text{
    padding-bottom: 10px;
    font-weight:light;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    
}
.test{
    padding-bottom: 100px;
}
.us-head{
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: 25px;
}
