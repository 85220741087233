.main{
 background: linear-gradient(rgb(238, 206, 238),rgb(230, 195, 230), #242424);
 margin-bottom:0px;
 align-items: center;
 align-content: center;
 display: flex;
    flex-flow: column;
    padding-top: 100px;
    width: 100%;
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.small-main{
    color:#242424;
    margin-bottom: 20px;
    align-content: center;
    align-self: center;
    align-items:center;
    

}
.large-main{
    align-content: center;
    align-self: center;
    align-items:center;
    
    color: aliceblue;
    margin-bottom: 40px;
}
@media screen and (max-width: 960px) {
    .main{
        align-items: center;
        align-self: center;
        align-content: center;
        display: flex;
        flex-flow: column;
    }
    .small-main{
            align-content: center;
            align-self: center;
            align-items:center;
            margin-bottom: 80px;
            display: flex;
            flex-flow: column;
            text-align: center;
    }
    .large-main{
        
            align-content: center;
            align-self: center;
            align-items:center;
            
            color: aliceblue;
            margin-bottom: 40px;
        
        
        
        
        color: aliceblue;
        margin-bottom: 40px;
    }
}
